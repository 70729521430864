import React from "react";
import styled from "@emotion/styled";
// components
import { DefaultLayout } from "../../components/layout/defaultLayout";
import { PageHeading } from "../../components/heading/pageHeading";
import { BreadcrumbBar } from "../../components/bar/breadcrumbBar";
import { BackBtn } from "../../components/button/backBtn";
// utils
import { Color } from "../../utils/color";
// image
import ImgPost from "../../images/sample.jpg";
// breadcrumb
const breadcrumbs = [
  { txt: "トップ", url: "/" },
  { txt: "お知らせ", url: "/news" },
  { txt: "記事タイトル", url: "/news/post" },
];

const PostPage = () => {
  return (
    <DefaultLayout pageTitle="お知らせ記事">
      <PageHeading enTxt="POST TITLE" jpTxt="記事タイトル" />
      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <SSection>
        <SContainer>
          <SArticle>
            <SHeading>
              <h2>記事タイトル</h2>
              <span>2022.01.01</span>
              <span>#tag</span>
            </SHeading>
            <SDetail>
              <img src={ImgPost} alt="sample" />
              記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト
              <br />
              <br />
              記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト記事内容サンプルテキスト
            </SDetail>
          </SArticle>
          <SFooting>
            <BackBtn />
          </SFooting>
        </SContainer>
      </SSection>
    </DefaultLayout>
  );
};

export default PostPage;

const SSection = styled.section`
  padding: 4rem 0;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }
`;

const SContainer = styled.div`
  width: 96%;
  max-width: 1366px;
  margin: auto;
`;

const SArticle = styled.article``;

const SHeading = styled.div`
  border-bottom: 1px dashed ${Color.gray100};
  padding-bottom: 2rem;
  h2 {
    font-weight: 700;
    margin-bottom: 1rem;
    line-height: 1;
  }
  span {
    line-height: 1;
    color: ${Color.gray200};
    font-size: 0.9rem;
    font-weight: 500;
    &:first-of-type {
      margin-right: 1rem;
    }
    &:last-of-type {
    }
  }
`;

const SDetail = styled.div`
  padding-top: 2rem;
  img {
    width: 100%;
    height: 320px;
    margin-bottom: 1rem;
    object-fit: cover;
  }
`;

const SFooting = styled.div`
  margin-top: 2rem;
  text-align: center;
`;
